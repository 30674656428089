<template>
  <v-stepper v-model="e1" alt-labels>
    <v-stepper-header>
      <v-spacer />

      <v-stepper-step
        :complete="e1 > 1"
        step="1"
        @click="e1 > 1 ? (e1 = 1) : null"
      >
        <span
          style="
            text-align: center;
            color: var(--v-primary-base);
            text-transform: uppercase;
          "
        >
          {{ $t("firstSteps.calendar") }}
        </span>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
        @click="e1 > 2 ? (e1 = 2) : null"
      >
        <span
          style="
            text-align: center;
            color: var(--v-primary-base);
            text-transform: uppercase;
          "
        >
          {{ $t("firstSteps.appointments") }}
        </span>
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 3"
        step="3"
        @click="e1 > 3 ? (e1 = 3) : null"
      >
        <span
          style="
            text-align: center;
            color: var(--v-primary-base);
            text-transform: uppercase;
          "
        >
          {{ $t("firstSteps.appointments") }}
        </span>
      </v-stepper-step>
      <v-spacer />
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" class="pt-4">
              <div
                style="
                  text-align: center;
                  color: var(--v-primary-base);
                  text-transform: uppercase;
                  padding-bottom: 15px;
                "
              >
                {{ $t("timetable", { name: $tc("studio") }) }}
              </div>
              <timetable
                ref="timetable"
                :timetable="user.timetable"
              ></timetable>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn
              style="height: 30px; margin-left: 10px"
              color="primary"
              @click="save()"
              elevation="0"
            >
              {{ $t("next") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-row justify="center">
          <v-col class="py-1" cols="12" md="5">
            {{ $tc("service", 2) }}
          </v-col>
          <v-col class="py-1" md="5" cols="12">
            <v-select
              :items="itemsServices"
              outlined
              dense
              multiple
              v-model="services"
              hide-details
              :item-text="(x) => $tc(x.name)"
              return-object
              data-vv-name="artist-type"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index <= 0">{{ $tc(item.name) }}</span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ services.length - 1 }} más)
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row justify="end" style="width: 100%">
          <v-btn outlined style="height: 30px" @click="e1--">
            {{ $t("back") }}</v-btn
          >
          <v-btn
            style="height: 30px; margin-left: 10px"
            color="primary"
            @click="e1++"
            elevation="0"
          >
            {{ $t("next") }}
          </v-btn>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <v-row>
          <v-col class="py-1" cols="12" md="5">
            {{ $t("policys.title") }}
          </v-col>
          <v-col class="py-1" cols="12" md="5">
            <v-select
              :items="policy_items"
              item-text="text"
              item-value="value"
              v-model="policy_appointment"
              outlined
              dense
              hide-details
              :disabled="disabled"
            ></v-select>
            <v-tooltip
              bottom
              v-if="
                this.$store.getters['auth/isLoggedIn'] &&
                $vuetify.breakpoint.lgAndUp
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="
                    $vuetify.breakpoint.smAndDown
                      ? 'position: absolute; right: 88px; top: 118px'
                      : 'position: absolute; right: 88px; top: 118px'
                  "
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <p class="py-0 mb-0" style="font-size: 12px">
                Adjunta un link o la información sobre políticas, términos y
                condiciones de tu estudio.
              </p>
              <p class="py-0 mb-0" style="font-size: 12px">
                Esta información es obligatoria que la acepte el artista en el
                proceso de invitación y el cliente final durante el proceso de
                cita.
              </p>
            </v-tooltip>
            <v-menu
              v-if="
                this.$store.getters['auth/isLoggedIn'] &&
                $vuetify.breakpoint.mdAndDown
              "
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="
                    $vuetify.breakpoint.smAndDown
                      ? 'position: absolute; right: 8px; top: 410px'
                      : 'position: absolute; right: 8px; top: 183px'
                  "
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>

              <p class="py-0 mb-0 px-5 pt-2" style="font-size: 12px">
                Adjunta un link o la información sobre políticas, términos y
                condiciones de tu estudio.
              </p>
              <p class="py-0 mb-0 px-5 pb-2" style="font-size: 12px">
                Esta información es obligatoria que la acepte el artista en el
                proceso de invitación y el cliente final durante el proceso de
                cita.
              </p></v-menu
            >
          </v-col>
          <v-col v-if="policy_appointment == 'link'" cols="10">
            <label
              class="py-0 pl-0 pl-sm-0"
              style="font-size: 12px !important"
              >{{ $t("policys.link_title") }}</label
            >
            <v-text-field
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'margin-top: 10px'
                  : 'margin-top: 15px'
              "
              outlined
              dense
              hide-details
              v-model="link"
              type="url"
              @blur="linked(link)"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="10"
            class="login_error white--text rounded-xl"
            v-if="policy_appointment == 'link' && !isUrl"
          >
            Introduce un enlace correcto. <br />Ejemplo:
            https://app.quickink.es/
          </v-col>
          <v-col align="right" v-if="policy_appointment == 'page'" cols="10">
            <PolicyEditor @validate="pagePolicy"></PolicyEditor>
          </v-col>
          <v-col class="py-1" cols="12" md="7">
            {{ $t("tattooers.reservation_system_complete") }}
          </v-col>
          <v-col class="py-1" cols="12" md="5">
            <LaborExange setting_key="labor_exange"></LaborExange>
            <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 20px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span style="font-size: 12px">
                <p>Cuando el cliente SELECCIONA un artista del estudio</p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-tooltip>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 130px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span class="py-0 mb-0 px-5" style="font-size: 12px">
                <p>Cuando el cliente SELECCIONA un artista del estudio</p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-menu>
          </v-col>

          <v-col class="py-1" cols="12" md="7">
            {{ $t("tattooers.reservation_system_tattooer") }}
          </v-col>
          <v-col class="py-1" cols="12" md="5">
            <LaborExange setting_key="labor_exange_tattooer"></LaborExange>
            <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 65px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span style="font-size: 12px">
                <p>
                  Cuando el cliente NO SABE el artista. El estudio deberá
                  asignar el artista al estudio.
                </p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-tooltip>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 235px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span class="py-0 mb-0 px-5" style="font-size: 12px">
                <p>
                  Cuando el cliente NO SABE el artista. El estudio deberá
                  asignar el artista al estudio.
                </p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-menu>
          </v-col>
          <v-col class="py-1" cols="12" md="9">
            {{ $t("tattooers.reservation_system_help_ask") }}
          </v-col>
          <v-col class="py-1" cols="12" md="3">
            <v-select
              :items="items"
              item-text="text"
              item-value="value"
              v-model="assistance"
              outlined
              dense
              hide-details
              :disabled="disabled"
            ></v-select>
            <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 115px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span style="font-size: 12px">
                Mediante el formulario de reserva del cliente, este puede
                solicitar asesoramiento para que el estudio lo gestione.
              </span>
            </v-tooltip>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 360px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span class="py-0 mb-0 px-5" style="font-size: 12px">
                Mediante el formulario de reserva del cliente, este puede
                solicitar asesoramiento para que el estudio lo gestione.
              </span>
            </v-menu>
          </v-col>
          <template v-if="assistance === 1">
            <v-col class="py-1" cols="12" md="7">
              {{ $t("tattooers.reservation_system_help") }}
            </v-col>
            <v-col class="py-1" cols="12" md="5">
              <LaborExange setting_key="labor_exange_help"></LaborExange>
              <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :style="'position: absolute; right: 8px; top: 160px'"
                    color="red"
                    @click="
                      $emit('changeTab');
                      $refs.card.dialog = false;
                    "
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="font-size: 12px">
                  <p>
                    Cuando el cliente solicita una cita para que el estudio lo
                    asesore
                  </p>
                  <ul>
                    <li>
                      <b>Día y hora:</b> El cliente puede seleccionar el día y
                      la hora disponible
                    </li>
                    <li>
                      <b>Bolsa de trabajo semanal:</b> El cliente puede
                      seleccionar una semana. El estudio/artista será el
                      encargado de proponer día y hora.
                    </li>
                    <li>
                      <b>Bolsa de trabajo mensual:</b> El cliente puede
                      seleccionar un mes. El estudio/artista será el encargado
                      de proponer día y hora.
                    </li>
                  </ul>
                </span>
              </v-tooltip>
              <v-menu
                v-if="$vuetify.breakpoint.smAndDown"
                :close-on-content-click="true"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :style="'position: absolute; right: 8px; top: 490px'"
                    color="red"
                    @click="
                      $emit('changeTab');
                      $refs.card.dialog = false;
                    "
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span class="py-0 mb-0 px-5" style="font-size: 12px">
                  <p>
                    Cuando el cliente solicita una cita para que el estudio lo
                    asesore
                  </p>
                  <ul>
                    <li>
                      <b>Día y hora:</b> El cliente puede seleccionar el día y
                      la hora disponible
                    </li>
                    <li>
                      <b>Bolsa de trabajo semanal:</b> El cliente puede
                      seleccionar una semana. El estudio/artista será el
                      encargado de proponer día y hora.
                    </li>
                    <li>
                      <b>Bolsa de trabajo mensual:</b> El cliente puede
                      seleccionar un mes. El estudio/artista será el encargado
                      de proponer día y hora.
                    </li>
                  </ul>
                </span>
              </v-menu>
            </v-col>
          </template>
        </v-row>

        <v-row>
          <v-col align="right" cols="12">
            <v-btn outlined style="height: 30px" @click="e1--">
              {{ $t("back") }}</v-btn
            >
            <v-btn
              @click="done"
              style="height: 30px; margin-left: 10px"
              elevation="0"
              ><v-icon small style="margin-right: 5px">$save</v-icon
              >{{ $t("save", { name: "" }) }}</v-btn
            >
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "FirstStep",
  components: {
    timetable: () => import("@/components/ui/Timetable"),
    LaborExange: () => import("@/components/user/settings/profile/LaborExange"),
    PolicyEditor: () =>
      import("@/components/user/settings/profile/PolicyEditor"),
  },

  methods: {
    ...mapActions("studios", ["getStudioTimetable", "editStudioTimetable"]),
    fetchStudioTimetable() {
      this.getStudioTimetable(this.id).then((data) => {
        this.user = data;
      });
    },
    save() {
      let has = false;
      let has_all = false;
      this.$refs.timetable.createError();
      this.user.timetable.forEach((x) => {
        if (x.length) has = true;
        x.forEach((y) => {
          if (y[0] === null || y[1] === null || y[0] > y[1]) has_all = true;
        });
      });

      if (has_all) {
        return;
      }
      if (!has) {
        this.$alert(this.$t("must_have_timetable"));
        return;
      }
      if (this.$store.getters["auth/isStudio"])
        this.editStudioTimetable({
          studio_id: this.$store.state.auth.user.id,
          formData: this.user,
        }).then((data) => {
          this.e1++;
        });
    },
    done() {
      this.$alert(this.$t("save_ok"), "", "");
      this.$router.push({ name: "Dashbord" });
    },

    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
    linked(value) {
      this.settingUpdate({ key: "link_appointment", value }).then((data) => {});
    },
    pagePolicy(value) {
      this.settingUpdate({
        key: "page_policy_appointment",
        value,
      }).then((data) => {});
    },
    activeLink() {
      this.link = this.$store.getters["auth/getSetting"]("link_appointment");
    },
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetchTypes() {
      this.getTypeArtist().then((tattooers) => {
        this.itemsServices = tattooers.data;
      });
    },
    ...mapActions("users", ["settingUpdate"]),
  },

  mounted() {
    this.activeLink();
    this.fetchTypes();
    this.$refs.appointments.dialog = this.$route.params.system ? true : false;
  },

  data() {
    return {
      disabled: false,
      link: null,
      e1: 2,
      user: { timetable: [[], [], [], [], [], [], []] },
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      itemsServices: [],
      policy_items: [
        { text: this.$t("policys.link"), value: "link" },
        { text: this.$t("policys.editor"), value: "page" },
      ],
      reservation_system_items: [
        { text: this.$t("reservation_systems.day"), value: "day" },
        { text: this.$t("reservation_systems.week"), value: "week" },
        { text: this.$t("reservation_systems.month"), value: "month" },
      ],
    };
  },

  computed: {
    reservation_system: {
      get() {
        return this.$store.getters["auth/getLaborExange"];
      },
      set(value) {
        //llamamos a la funcion de crear setting
        this.settingUpdate({ key: "labor_exange", value }).then((data) => {});
      },
    },
    assistance: {
      get() {
        return this.$store.getters["auth/getSetting"]("assistance_appointment");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        if (value === 0) {
          this.$confirm(this.$t("desactivate_assistance")).then(() => {
            this.settingUpdate({ key: "assistance_appointment", value }).then(
              (data) => {
                this.$alert(this.$t("desactivated"));
              }
            );
          });
          return;
        }
        this.settingUpdate({ key: "assistance_appointment", value }).then(
          (data) => {
            this.$alert(this.$t("activated"));
          }
        );
      },
    },
    isUrl() {
      let pattern =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/; // fragment locator
      return !!pattern.test(this.link);
    },

    policy_appointment: {
      get() {
        return this.$store.getters["auth/getSetting"]("policy_appointment");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        if (value === "link") this.activeLink();
        this.settingUpdate({
          key: "policy_appointment",
          value,
        }).then((data) => {});
      },
    },
    services: {
      get() {
        return JSON.parse(
          this.$store.getters["auth/getSetting"]("studio_services")
        );
      },
      set(value) {
        this.settingUpdate({
          key: "studio_services",
          value,
          json: true,
        }).then((data) => {});
      },
    },
  },
};
</script>
<style lang="sass">
.v-select-list .v-list-item__title, .v-menu__content .v-list .v-list-item__title, .v-menu__content .v-list--nav .v-list-item .v-list-item__title, .v-menu__content .v-list--nav .v-list-item, .v-menu__content.v-menu__content .theme--dark.v-list-item, .theme--dark.v-list-item--disabled
  //padding-bottom: 5px
</style>